import { Button } from "react-bootstrap";
import DeathDash from "../img/deathdash.png";

function EarlyAccessPage() {
    return (
        <div style={{ "margin": "20px" }}>
            <h1 className="title default-font" style={{"fontSize": "50px"}}>Death Dash</h1>

            <img className="centered-image" src={DeathDash} alt="Death Dash" width="80%" />

            <br />

            <p className="default-font">Death Dash is a fast-paced, minimalist endless runner game designed to test your reflexes. In this intense, no-frills experience, your objective is simple: move forward, avoid the red obstacles, and crash through the white walls to keep your momentum going. The game is all about precision and timing—one wrong move, and it’s game over!</p>
            <p className="default-font">Currently, Death Dash is in closed beta. If you want to participate, fill the form below.</p>

            <hr />

            <h2 className="default-font">Enter your email address to get access to the closed-testing app.</h2>

            {/* Form name should match the hidden input */}
            <form name="earlyaccess-info" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                {/* This hidden input is required for Netlify to process the form */}
                <input type="hidden" name="form-name" value="earlyaccess-info" />
                
                {/* Honeypot field to prevent bots */}
                <p style={{ display: "none" }}>
                    <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                </p>

                <input className="default-font" name="emailadress-earlyaccess" type="email" placeholder="Email Address" required />

                <br /><br />

                <Button className="default-font" variant="outline-primary" type="submit">Submit</Button>
            </form>

            <br />

            <h4 className="default-font">After you submit the form, you should wait to get message to the email you entered and then you will be able to access the closed-testing app.</h4>
            <a className="default-font" href="https://play.google.com/store/apps/details?id=com.AlexBGames.deathdash">Google Play link for Android</a>

            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
    );
}

export default EarlyAccessPage;